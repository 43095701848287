import React from 'react';

import SectionBlock from '../components/Block/SectionBlock';

const sections = [
  {
    img: false,
    text: [
      {
        title: 'Customer',
        description:
          '<p>Our client was a company that produces software for strategic innovation management. The software is used ' +
          'by a number of global companies, from various markets, including logistics and automotive. </p>',
      },
      {
        title: 'Challenge',
        description:
          '<ul>' +
          '<li>Need to develop roadmap visualisation as a new feature requested by </li>' +
          '<li>Lack of resources qualified in front-end JS developers familiar with graphical libraries</li>' +
          '</ul>',
      },
      {
        title: 'Solution',
        description:
          '<ul>' +
          '<li>Quick start of SolveQ agile team</li>' +
          '<li>SolveQ responsibility:' +
          '<ul>' +
          '<li>choice of tools and libraries to deliver expected functionality</li>' +
          '<li>development of new features</li>' +
          '<li>integration with the main application</li>' +
          '<li>tuning the application according to customers needs</li>' +
          '</ul>' +
          '</li>' +
          '</ul>',
      },
      {
        title: 'Results ',
        description:
          '<ul>' +
          '<li>New functionality implemented in 6 months</li>' +
          '<li>Graphical presentation of the innovation process warmly accepted by final customers</li>' +
          '</ul>',
      },
      {
        title: 'Technologies ',
        description:
          '<ul>' +
          '<li>Languages:' +
          '<ul>' +
          '<li>TypeScript</li>' +
          '<li>TJavaScript</li>' +
          '</ul></li>' +
          '<li>Frameworks:' +
          '<ul>' +
          '<li>VueJS</li>' +
          '</ul>' +
          '</li>' +
          '<li>Libraries' +
          '<ul>' +
          '<li>KonvaJs</li>' +
          '<li>Vuex</li>' +
          '<li>Vuetify</li>' +
          '<li>Axios</li>' +
          '</ul>' +
          '</li>' +
          '<li>Repository:' +
          '<ul>' +
          '<li>Gitlab</li>' +
          '</ul>' +
          '</li>' +
          '</ul>',
      },
    ],
  },
];

const HypePage = () => {
  return (
    <>
      <div className="th-hero-page">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="th-hero-content">
                <h1 className="th-hero-content__header">Vue and Html Canvas Application</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <SectionBlock imgFull imgFirst {...sections[0]} />
    </>
  );
};

export default HypePage;
